.item-slide
    box-sizing: border-box

.modal
    display: none
    position: fixed
    z-index: 31
    left: 0
    top: 0
    width: 100%
    height: 100%
    background-color: rgba(0,0,0,0.5)
    padding: 3.5rem

.modal-content
    position: relative
    background-color: rgba(0,0,0,0)
    width: 90%
    max-width: 1200px
    max-height: 700px
    overflow: hidden
    text-align: center

.modal img
    height: 100vh

.item-slide
    display: none

/* Next & previous buttons */
.prev,
.next
    cursor: pointer
    position: absolute
    top: 50%
    width: auto
    padding: 16px
    margin-top: -50px
    color: white
    font-weight: bold
    font-size: 20px
    transition: 0.6s ease
    border-radius: 0 3px 3px 0
    user-select: none
    -webkit-user-select: none

/* Position the "next button" to the right */
.next
    right: 0
    border-radius: 3px 0 0 3px

/* Number text (1/3 etc) */
.numbertext
    color: #f2f2f2
    font-size: 12px
    padding: 8px 12px
    position: absolute
    right: 0
    top: 0

.item-slide img
    max-width: 1200px

.caption-container
    color: white

.column img
    margin-top: 20px
