
@charset "utf-8"
$family-sans-serif: "Myriad Set Pro", "Myriad Pro", "Helvetica Neue", Helvetica, Arial, sans-serif
$size-5: 20px
$size-6: 16px
$nav-height: 44px
@import "../../node_modules/bulma/bulma"
@import "gallery"

body
    background-color: #F5F6FA

.site
    display: flex
    min-height: 100vh
    flex-direction: column

.site-content
    flex: 1

.navbar-item img
    max-height: 2.25rem

.navbar-center
    flex-grow: 1
    justify-content: center
    margin-right: 102px

.app-icon
    border-radius: 20%

.footer
    background-color: #344157
    border-top: 3px solid #03b2e3
    color: #ffffff !important
    padding: 3rem 1.5rem 0.5rem

    .phone
        color: #ffffff
        &:hover
            color: #03b2e3
    a:hover
        color: #03b2e3

.footer_title
    color: #ffffff !important

footer .hero-body
    padding: 0 1.5rem
